import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import PageContext from '@context'
import { Box, Button as MuiButton, IconButton } from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'
import useParams from '@hooks/use-params'
import {
  addPrecedingSlash,
  addTrailingSlash,
  convertStringToTitleCase,
  isCurrentUrl,
  isInBrowser,
  removeTrailingSlash,
  scrollToElement,
} from '@helpers'
import { monochrome } from '@helpers/palette'
import classNames from 'classnames'
import renderBloks from '@renderBloks'
import { Modal } from '@system'
import get from 'lodash/get'
import Icon from '@system/icon'
import useStringTranslation from '@hooks/use-string-translation'

const useStyles = makeStyles((theme) => ({
  rootButton: {
    boxShadow: 'none',
    fontWeight: 700,
    minWidth: (props) =>
      props.size === 'extraLarge'
        ? '230px'
        : props.size === 'large'
        ? '150px'
        : props.size === 'medium'
        ? '113px'
        : '90px',
    minHeight: (props) =>
      props.size === 'extraLarge'
        ? '55px'
        : props.size === 'large'
        ? '45px'
        : props.size === 'medium'
        ? '32px'
        : props.size === 'none'
        ? '0px'
        : '26px',
    padding: (props) =>
      props.isNavCTA
        ? '5px 12px'
        : `${props.topPadding}px ${props.leftPadding}px`,
    '&:hover': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: (props) => props.size === 'extraLarge' && '180px',
      minHeight: (props) => props.size === 'extraLarge' && '43px',
    },
  },
  rootCircle: {
    color: ({ textColor }) => textColor,
    backgroundColor: ({ backgroundColor, variant }) =>
      variant === 'circular_outlined' ? 'transparent' : backgroundColor,
    border: ({ backgroundColor, variant }) =>
      variant === 'circular_outlined' ? `3px solid ${backgroundColor}` : '0',
    boxShadow: 'none',
    fontWeight: 700,
    width: ({ size }) =>
      size === 'extraLarge'
        ? '150px'
        : size === 'large'
        ? '100px'
        : size === 'medium'
        ? '87px'
        : size === 'small'
        ? '67px'
        : '87px',

    height: ({ size }) =>
      size === 'extraLarge'
        ? '150px'
        : size === 'large'
        ? '100px'
        : size === 'medium'
        ? '87px'
        : size === 'small'
        ? '67px'
        : size === 'none'
        ? '0px'
        : '87px',
    [theme.breakpoints.down('sm')]: {
      width: ({ size, variant }) =>
        size === 'extraLarge'
          ? '100px'
          : size === 'large'
          ? '70px'
          : size === 'medium'
          ? '50px'
          : size === 'small' && variant === 'circular_outlined'
          ? '67px'
          : '25px',
      height: ({ size, variant }) =>
        size === 'extraLarge'
          ? '100px'
          : size === 'large'
          ? '70px'
          : size === 'medium'
          ? '50px'
          : size === 'small' && variant === 'circular_outlined'
          ? '67px'
          : size === 'none'
          ? '0px'
          : '25px',
    },
  },
  endIcon: {
    top: 8,
    position: 'absolute',
    right: '9px',
  },
  marketoFormSubmitButton: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: (props) => !props.isTwoColumn && '6px 16px !important',
  },
  label: {
    ...theme.typography.button,
    fontSize: (props) =>
      props.size === 'extraLarge'
        ? '1.25rem'
        : props.size === 'large'
        ? '0.9rem'
        : props.size === 'small'
        ? '0.727rem'
        : '0.727rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: (props) => props.size === 'extraLarge' && '0.95rem',
    },
    textTransform: (props) =>
      !!props.textTransform && props.textTransform + `!important`,
  },
  icon: {
    fontSize: (props) =>
      props.size === 'extraLarge' ? '2.25rem !important' : 'initial',
  },
  iconButtonIcon: {
    fontSize: '50px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px !important',
    },
  },
  tinySpacer: {
    cursor: 'pointer',
    fontSize: ({ variant }) =>
      variant === 'circular_outlined' ? '18px' : 'inherit',
    paddingBottom: '5px',
    paddingTop: ({ variant }) =>
      variant === 'circular_outlined' ? '14px' : '5px',
  },
  circleButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
    alignItems: 'center',
    marginBottom: '20px',
  },
  text: {
    color: ({ textColor }) => textColor,
  },
  contained: {
    color: ({ textColor }) => textColor,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    '&:hover': {
      color: ({ textColor }) => textColor,
      backgroundColor: ({ backgroundColor }) => backgroundColor,
    },
  },
  outlined: {
    color: ({ textColor }) => textColor,
    border: ({ textColor }) => `1px solid ${textColor}`,
    '&:hover': {
      border: ({ textColor }) => `1px solid ${textColor}`,
    },
  },
  noTextDecoration: {
    textDecoration: 'none',
    borderBottom: 'none',
    width: (props) => props.fullWidth && '100%',
    '& *': {
      textDecoration: 'none',
      borderBottom: 'none',
    },
  },
  addTwoRem: {
    width: 'calc(100% + 2rem) !important',
  },
  pricingCTA: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  disabledOL: {
    color: `${theme.palette.text.disabled} !important`,
    border: `1px solid ${theme.palette.text.disabled} !important`,
    backgroundColor: 'transparent !important',
  },
}))

const RenderedCTA = (props) => {
  const {
    text,
    size,
    variant,
    disabled,
    fullWidth,
    icon,
    openInNewTab,
    bgColor,
    color,
    className,
    modal,
  } = props

  const modalForm = get(
    modal?.filter((blok) => blok.component === 'marketoFormModule'),
    '[0]',
    {}
  )

  const toggleMutiny = (MutinyConv) => {
    window.mutiny = window.mutiny || []
    window.mutiny?.client?.trackConversion({ name: MutinyConv })
  }

  const handleClick = (e) => {
    if (props.onClick) {
      props.onClick(e)
    }

    if (props.isWistiaLink) {
      e.preventDefault()
    }

    if (props?.modal?.length > 0) {
      toggleModal(true)
    }

    if (props?.blok?.mutinyConversion?.length > 0) {
      toggleMutiny(props.blok.mutinyConversion)
    }
  }

  const { title, smallerWidth } = modalForm

  const styleProps = props

  const [shouldOpenModal, toggleModal] = useState(false)

  const calcTopPadding = (size) => {
    switch (size) {
      case 'small':
        return 4
      case 'medium':
        return 6
      case 'large':
        return 8
      default:
    }
  }

  const textColor = props.isMobileNavContent
    ? '#fff'
    : monochrome((color && color.color) || '#fff')
  const backgroundColor = monochrome((bgColor && bgColor.color) || '#000002')

  const calcLeftPadding = (top) => (top * 15) / 10

  const topPadding = calcTopPadding(size)
  const leftPadding = calcLeftPadding(topPadding)
  const textTransform = props.textTransform
  const classes = useStyles({
    ...styleProps,
    isTwoColumn: props.isTwoColumn,
    topPadding,
    leftPadding,
    textColor,
    backgroundColor,
    variant,
    textTransform,
  })

  if (variant === 'circular' || variant === 'circular_outlined') {
    return (
      <Box className={classes.circleButtonBox}>
        <IconButton
          classes={{
            root: classes.rootCircle,
            text: classes.text,
            label: classes.label,
          }}
          id={props.buttonId}
          variant={'circular'}
          marketo-id={props.marketoId}
          mutinyConversion={props.blok.mutinyConversion}
          fullWidth={fullWidth}
          disabled={disabled || props.disabled}
          type={props.type}
          onClick={handleClick}
          target={openInNewTab ? '_BLANK' : null}
          disableFocusRipple
          disableRipple
        >
          <Icon styles={classes.iconButtonIcon}>{icon}</Icon>
        </IconButton>
        <Box className={classes.tinySpacer} onClick={handleClick}>
          {text}
        </Box>
      </Box>
    )
  }
  return (
    <>
      <MuiButton
        classes={{
          label: classes.label,
          root: classes.rootButton,
          text: classes.text,
          contained: classes.contained,
          outlined: classes.outlined,
          endIcon: props.isTwoColumn ? classes.endIcon : '',
          disabled: variant === 'outlined' && classes.disabledOL,
        }}
        className={classNames(className, {
          [classes.marketoFormSubmitButton]: props.isMarketoFormSubmitButton,
          [classes.addTwoRem]: props.isPricingCTA && disabled,
          [classes.pricingCTA]: props.isPricingCTA,
        })}
        id={props.buttonId}
        variant={variant}
        marketo-id={props.marketoId}
        size={size !== 'extraLarge' ? size : ''}
        fullWidth={fullWidth}
        disabled={disabled || props.disabled}
        type={props.type}
        onClick={handleClick}
        endIcon={icon && <Icon styles={classes.icon}>{icon}</Icon>}
        target={openInNewTab ? '_BLANK' : null}
        disableFocusRipple
        disableRipple
        data-analytics={
          props?.modal?.length > 0 && props['data-analytics']
            ? props['data-analytics']
            : null
        }
        data-product={
          props?.modal?.length > 0 && props['data-product']
            ? props['data-product']
            : null
        }
      >
        {props.children || text}
      </MuiButton>
      {!!shouldOpenModal && (
        <Modal
          open={shouldOpenModal}
          handleClose={() => toggleModal(false)}
          title={title}
          smallerWidth={smallerWidth}
          meetupDetails={props.meetupDetails}
          modalIsDark={props.modalIsDark}
        >
          {!!modal &&
            renderBloks(modal, {
              isModalContent: true,
              hiddenFields: props.hiddenFields,
            })}
        </Modal>
      )}
    </>
  )
}
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = (props) => {
  const {
    linksTo: { url, linktype, story },
    text,
    isLink,
    params,
    fragment,
    disabled,
    anchorLinkIsInvisible,
    updateHiddenFieldsValue,
    forwardUTMParams,
    parentUrl,
    isButtonAssetDownload,
    dataAnalytics,
    dataProduct,
    mutinyConversion,
    ...other
  } = props

  const classes = useStyles(props)
  const context = useContext(PageContext)
  const locationProps = context.location && context.location.state

  // TODO: refactor these variables/ param helper to something easier to read and more efficient
  const hash = fragment ? (fragment[0] === '#' ? fragment : `#${fragment}`) : ''

  const isStory = linktype === 'story'
  const sb_url = isStory && story && story.url
  const storyName = isStory && story && story.name
  const storyUrl = isStory ? removeTrailingSlash(sb_url).slice(3) : null
  const existingSearch = url.indexOf('?') > 0

  const query = useParams(params, url, forwardUTMParams)
  const isJustAnchorLink = isStory && !storyUrl && !!hash && hash.length > 0

  const initHref = isJustAnchorLink
    ? hash
    : isStory
    ? context.isInEditor
      ? `/editor/?path=${addTrailingSlash(sb_url)}${query.replace(
          '?',
          '&'
        )}${hash}`
      : addPrecedingSlash(`${addTrailingSlash(storyUrl)}${query}${hash}`)
    : parentUrl || url
    ? addPrecedingSlash(
        parentUrl ||
          `${url.trim()}${existingSearch ? query.replace('?', '&') : query}`
      )
    : ''

  const [href, setHref] = useState(initHref)

  useEffect(() => {
    setHref(initHref)
  }, [initHref])

  const anchorLink = isCurrentUrl(href) || (!!fragment && (!isStory || !url))

  // // Tailor the following test to your environment.
  // // This example assumes that any internal link (intended for Gatsby)
  // // will start with exactly one slash, and that anything else is external.

  const internal = /^\/(?!\/)/.test(href) || isStory
  const analytics = {}
  if (dataAnalytics) analytics['data-analytics'] = dataAnalytics
  if (dataProduct) {
    analytics['data-product'] = dataProduct
  } else analytics['data-product'] = 'generic'
  const handleClick = () => {
    !!locationProps && isInBrowser && window.open(locationProps.asset)
  }

  if ((internal && anchorLink) || isJustAnchorLink) {
    return anchorLinkIsInvisible || isJustAnchorLink ? (
      <RenderedCTA
        text={text || storyName}
        onClick={(e) => {
          e.preventDefault()
          scrollToElement(hash)
        }}
        {...other}
      />
    ) : context.isInEditor ? (
      <a {...analytics} href={href} className={classes.noTextDecoration}>
        <RenderedCTA text={text || storyName} {...other}>
          {text || storyName}
        </RenderedCTA>
      </a>
    ) : (
      <GatsbyLink
        to={href}
        className={classes.noTextDecoration}
        activeClassName="active"
        partiallyActive={false}
        onClick={(e) => {
          if (updateHiddenFieldsValue) updateHiddenFieldsValue(true)
        }}
      >
        <RenderedCTA text={text || storyName} {...other} />
      </GatsbyLink>
    )
  } else if (isButtonAssetDownload && !!locationProps) {
    return (
      <RenderedCTA text={text || storyName} onClick={handleClick} {...other} />
    )
  }

  return disabled ? (
    <RenderedCTA disabled={true} text={text || storyName} {...other}>
      {text || storyName}
    </RenderedCTA>
  ) : (
    <a href={href} {...analytics} className={classes.noTextDecoration}>
      <RenderedCTA text={text || storyName} {...other}>
        {text || storyName}
      </RenderedCTA>
    </a>
  )
}

const Button = (props) => {
  const {
    linksTo,
    modal,
    variant,
    isNavCTA,
    isPricingCTA,
    isWistiaLink,
    onClick,
    isLink,
    marketoId,
    type,
    isMarketoFormSubmitButton,
    buttonId,
    disabled,
    isTwoColumn,
    openInNewTab,
    updateHiddenFieldsValue,
    parentUrl,
    dataAnalytics,
    dataProduct,
    className,
    fragment,
    mutinyConversion,
    ...otherProps
  } = props
  const text = useStringTranslation(props.text || '')
  const titleCaseText = convertStringToTitleCase(text)
  const analytics = {}
  if (dataAnalytics) analytics['data-analytics'] = dataAnalytics
  if (dataProduct) {
    analytics['data-product'] = dataProduct
  } else analytics['data-product'] = 'generic'
  const hasValidLink = !!linksTo?.story?.url || !!linksTo?.url || !!fragment
  return (
    <>
      {(hasValidLink || parentUrl) &&
      !isWistiaLink &&
      !isMarketoFormSubmitButton &&
      (!modal || modal.length === 0) ? (
        <Link
          style={{
            textDecoration: variant === 'text' ? 'underline' : 'none',
          }}
          isNavCTA={isNavCTA}
          isPricingCTA={isPricingCTA}
          updateHiddenFieldsValue={updateHiddenFieldsValue}
          target={openInNewTab ? '_BLANK' : null}
          parentUrl={parentUrl}
          {...props}
          {...analytics}
          text={titleCaseText}
        ></Link>
      ) : (
        <RenderedCTA
          isWistiaLink={isWistiaLink}
          isPricingCTA={isPricingCTA}
          mutinyConversion={mutinyConversion}
          isNavCTA={isNavCTA}
          onClick={onClick}
          marketoId={marketoId}
          isTwoColumn={isTwoColumn}
          type={type}
          buttonId={buttonId}
          disabled={disabled}
          variant={variant}
          isMarketoFormSubmitButton={isMarketoFormSubmitButton}
          modal={modal}
          className={className}
          {...otherProps}
          {...analytics}
          text={titleCaseText}
        ></RenderedCTA>
      )}
    </>
  )
}

export default Button
